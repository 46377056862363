html {
  overflow-x: hidden;
}

li {
  list-style-position: inside;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000 !important;
}

@font-face {
  font-family: 'RobotoRgular';
  src: url('../public/assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../public/assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/assets/fonts/Roboto-Black.woff2') format('woff2'),
    url('../public/assets/fonts/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoLight';
  src: url('../public/assets/fonts/Roboto-Light.woff2') format('woff2'),
    url('../public/assets/fonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('../public/assets/fonts/Roboto-Medium.woff2') format('woff2'),
    url('../public/assets/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoBold';
  src: url('../public/assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../public/assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoThin';
  src: url('../public/assets/fonts/Roboto-Thin.woff2') format('woff2'),
    url('../public/assets/fonts/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-loader {
  position: relative !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.loader-forms {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 9999999999999999;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid #f6c12c;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #edb35f transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid #ef1d1d;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ef1d1d transparent transparent transparent;
}




/* html.touch *:hover {
  all:unset!important;
} */

.remove-pad {
  padding-bottom: 0 !important;
}

.add-margin-top {
  margin-top: -90px !important;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.3);
}

.on-hover-overlay:hover .overlay {
  opacity: 1;
}

.cover {
  object-fit: cover;
}

.contain {
  object-fit: contain;
}

.w-fit {
  width: fit-content !important;
}

.h-fit {
  height: fit-content !important;
}

.pointer {
  cursor: pointer;
}

nav {
  padding: 45px 0px;
}

.logo {
  width: 75%;
}

.menu-button {
  width: 32px;
  height: auto;
}

/* ANIMATION */
[animate] {
  transition: transform 1s 0.5s, opacity 1s 0.5s;
  opacity: 0;
}

[animate].show {
  opacity: 1;
}

[animate='left'] {
  transform: translateX(-15px);
}

[animate='left'].show {
  transform: translateX(0);
}

[animate='right'] {
  transform: translateX(15px);
}

[animate='right'].show {
  transform: translateX(0);
}

[animate='up'] {
  transform: translateY(-15px);
}

[animate='up'].show {
  transform: translateY(0);
}

[animate='down'] {
  transform: translateY(15px);
}

[animate='down'].show {
  transform: translateY(0);
}

.no-transition-delay {
  transition-delay: 0s;
}

/*  */

h1 {
  font-size: 127px !important;
  line-height: 120px !important;
  margin-bottom: 0 !important;
}

.small-title h1 {
  font-size: 100px !important;
  line-height: 110px !important;
  margin-bottom: 0 !important;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  opacity: 0;
  transition: all 0.5s;
  pointer-events: none;
}

.show {
  opacity: 1;
  pointer-events: all;
}

.close-menu-button {
  width: 34px;
  height: 34px;
  margin-top: 41.2px;
}

.black-back {
  position: absolute;
  top: 0;
  right: 0;
  width: calc((100vw / 2) - (1115px / 2));
  height: 100%;
  background-color: black;
  z-index: -1;
}

.menu-items ul {
  list-style-type: none;
}

.active-nav-item {
  color: #ef1d1d !important;
}

.menu-items ul li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 40px;
  line-height: 70px;
}

.menu-items ul li a:hover {
  color: #ef1d1d;
}

.menu-social {
  width: 70px;
  height: 100%;
  background-color: #ef1d1d;
  padding: 24px 10px;
}

.social-icons {
  transition: all 0.4s;
}

.social-icons:hover {
  /* transform: translateY(-3px); */
  filter: invert(13%) sepia(94%) saturate(7466%) hue-rotate(0deg) brightness(94%) contrast(115%);
}

.social-icons-menu {
  transition: all 0.3s;
}

.social-icons-menu:hover {
  transform: translateY(-3px);
}

/* 
.social-icons-footer img {
  filter: invert(90%);
} */

.footer-sec2 {
  padding-top: 74px;
  padding-bottom: 43px;
}

.footer-menu-items a {
  text-decoration: none;
  color: #05090c;
  font-size: 35px;
  line-height: 44px;
}

.footer-menu-items a:hover {
  color: #ef1d1d;
}

.footer-follow-text {
  font-size: 35px;
  line-height: 48px;
  color: #05090c;
}

.footer-i-img img {
  height: 212px;
}

.copyright-text {
  font-family: 'RobotoLight', Arial, sans-serif;
  font-size: 15px;
  margin-top: 60px !important;
}

.ratio-bg-footer1 {
  --bs-aspect-ratio: 52.1%;
  background-color: black;
}

.ratio-bg-footer1 img {
  opacity: 0.7;
}

.ratio-bg-footer2 {
  --bs-aspect-ratio: 52.1%;
  background-color: #d31c1c;
}

.footer-sec1-p1 {
  padding: 100px 0;
}

.footer-sec1-p2 {
  padding-left: 119px !important;
  padding-top: 100px;
  padding-bottom: 100px;
}

.text-footer-sec1-1 {
  font-size: 45px;
  line-height: 45px;
  color: #ffffff;
}

.text-footer-sec1-2 {
  font-size: 45px;
  line-height: 45px;
  color: #ffffff;
}

.red-button {
  min-width: 250px;
  border-radius: 30px;
  background-color: #ef1d1d;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  transition: all 0.4s;
}

.red-button:hover {
  background-color: white;
  color: #ef1d1d;
}

.red-button-border {
  min-width: 250px;
  border-radius: 30px;
  background-color: #ef1d1d;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  transition: all 0.4s;
  border: 1px solid #ef1d1d;
}

.red-button-border:hover {
  background-color: white;
  color: #ef1d1d;
}

.black-button {
  min-width: 250px;
  border-radius: 30px;
  background-color: #05090c;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 10px;
  transition: all 0.4s;
}

.black-button:hover {
  background-color: white;
  color: #05090c;
}

.back {
  z-index: -1;
}

.nav-front {
  z-index: 99999;
}

.margin-content {
  margin-top: 120px;
}

.home-banner {
  margin-top: -121px;
  padding-top: 230px;
  max-height: 650px;
  height: 650px;
}

.home-banner-dots .swiper-pagination-bullet {
  background-color: #05090c;
  opacity: 1;
  width: 5px;
  height: 5px;
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 10px) !important;
}

.home-banner-dots .swiper-pagination-bullet-active {
  background-image: url(../src/assets/fixed-images/active-pagination-home.svg);
  background-size: contain !important;
  height: 25px;
  width: 6px;
  border-radius: 0;
  background-repeat: no-repeat;
  background-color: transparent !important;
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 10px) !important;
}

.home-banner-slider-text h3 {
  font-family: 'RobotoMedium', sans-serif;
  font-size: 50px;
  line-height: 55px;
  color: #000000;
}

.home-banner-slider-text h2 {
  font-size: 60px;
  line-height: 55px;
  color: #000000;
}

.home-banner-slider-text .regular-home {
  font-family: 'RobotoMedium', sans-serif;
  font-size: 60px;
  line-height: 55px;
  color: #000000;
}

.home-banner-button {
  background-color: #ef1d1d;
  border-radius: 0 50px 50px 0;
  width: calc((100vw / 2) - (1300px / 2));
  min-width: calc((100vw / 2) - (1300px / 2));
  padding: 0;
  transition: all 0.7s ease-in-out;
}

.home-banner-button:hover {
  width: 100%;
  padding: 0 50px;
}

.home-banner-button p {
  opacity: 0;
  transition: all 1s;
  white-space: nowrap;
  pointer-events: none;
}

.home-banner-show-button:hover .home-banner-button {
  width: 100%;
  padding: 0 50px 0 calc((100vw / 2) - (1300px / 2));
}

.home-banner-button:hover p {
  opacity: 1;
  pointer-events: all;
  /* transition: all .8s; */
}

.home-banner-show-button:hover .home-banner-button p {
  opacity: 1;
  pointer-events: all;
}

.home-banner-icons {
  margin-top: 90px !important;
  padding-bottom: 50px !important;
}

.home-banner-icons a {
  text-decoration: none;
}

.home-banner-contact-icon {
  width: 55px;
  height: 55px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.4s;
}

.home-banner-contact-icon:hover {
  transform: translateY(-4px);
}

.home-banner-contact-icon img {
  max-width: 28px;
  max-height: 28px;
}

.home-banner-social-text {
  color: #05090c;
  margin-left: 14px;
}

.home-banner-social-text-right {
  color: #05090c;
  margin-right: 14px;
}

.home-banner-button-w {
  width: calc((100vw / 2) - (1115px / 2));
}

.home-banner-button-img {
  width: 60px !important;
  height: 60px;
}

.human {
  position: absolute;
  bottom: 0;
  right: 15%;
  height: 85%;
  width: 28%;
  background-color: #ef1d1d;
}

#particles2 {
  position: absolute;
  bottom: 0;
  right: calc((100vw / 2) - (980px / 2));
  width: 380px;
  height: 100%;
  transform: translateY(35px);
  z-index: 10;
}

.main-canvas-bg {
  background-image: radial-gradient(circle at center,
      white 0,
      #e5e5e7 50%,
      #d7d7d8 100%);
  z-index: -1;
}

.main-canvas-bg-white {
  background-color: white;
  z-index: -1;
}

.wwdcard {
  border-radius: 50%;
  background-image: linear-gradient(135deg, #565555 0%, #030101 100%);
  transition: background-image 0.8s linear;
  transition: all 0.5s;
}

.wwd-card-initial-content {
  opacity: 1;
  transition: all 0.8s;
}

.wwdcard.what-we-do.red-color {
  background-color: #d31c1c !important;
  background-image:none !important;
  transition: background-image 0.5s;
}

.wwdcard.what-we-do.gradient-color {
  background-image: linear-gradient(135deg, #565555 0%, #C3C3C3 100%) !important;
  transition: all 0.5s;
}

.wwdcard.what-we-do.gradient-color:hover {
  background-image: linear-gradient(135deg, #565555 0%, #030101 100%) !important;
  background-color: transparent !important;
}

.wwdcard.what-we-do.red-color:hover {
  background-image: linear-gradient(135deg, #565555 0%, #030101 100%) !important;
  background-color: transparent !important;
}


/* .wwdcard:hover .wwd-card-initial-content {
  opacity: 0;
  transition: all 0.5s;
} */

.wwdcard-img {
  max-width: 70px;
  max-height: 70px;
}

.wwdcard-text {
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  padding-top: 20px;
}

.wwdcard-add-text {
  opacity: 0;
  transition: all 0.8s;
  background-color: #ef1d1d;
  border-radius: 50%;
}

.wwdcard-add-text.black-hover:hover {
  background-image: linear-gradient(135deg, #565555 0%, #030101 100%) !important;
  background-color: transparent !important;
}

.wwdcard-add-text p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 3; number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.wwdcard:hover .wwdcard-add-text {
  opacity: 1;
}

.wwdcard-add-text p {
  font-family: 'RobotoLight', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.home-wwd-slider {
  position: relative;
}

.slider-prev-button {
  background-image: url(../src/assets/fixed-images/next-button.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: contain;
  transform: scaleX(-1);
  left: 0;
  cursor: pointer;
}

.slider-prev-button:after {
  content: '';
}

.home-sec1 {
  margin-top: -40px !important;
}

.swiper-button-disabled {
  background-image: url(../src/assets/fixed-images/next-button-inactive.svg) !important;
}

.slider-next-button {
  background-image: url(../src/assets/fixed-images/next-button.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: contain;
  cursor: pointer;
}

.slider-next-button-rw {
  background-image: url(../src/assets/fixed-images/next-button.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: contain;
  cursor: pointer;
}

.home-banner-image {
  margin-top: -230px;
  filter: grayscale(1);
  opacity: 18%;
  object-fit: cover;
  /* background-position: 100% 100%; */
}

.video-banner-image {
  margin-top: -230px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.middle-page {
  position: absolute;
  top: 50%;
  left: 50%;
}

.slider-prev-button-rw {
  background-image: url(../src/assets/fixed-images/next-button.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: contain;
  transform: scaleX(-1);
  left: 0;
  cursor: pointer;
}

.slider-next-button-oc-mob {
  background-image: url(../src/assets/fixed-images/next-button.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: contain;
  cursor: pointer;
}

.slider-prev-button-oc-mob {
  background-image: url(../src/assets/fixed-images/next-button.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: contain;
  transform: scaleX(-1);
  left: 0;
  cursor: pointer;
}

.slider-next-button:after {
  content: '';
}

.title .text-uppercase {
  text-align: center;
}

.title .title-outlined {
  display: inline-block;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ef1d1d;
}

.related-work-border {
  border-radius: 50%;
  border: 1px solid #ffffff;
  transform: translateZ(0);
}

.related-work-border img {
  transform: scale(1);
  transition: all 0.5s;
}

.related-work-border:hover img {
  transform: scale(1.1);
}

.related-work-text {
  position: absolute;
  bottom: 18%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 77%;
}

.related-work-text p {
  color: #ffffff;
}

.related-work-ratio {
  width: 100%;
  height: 100%;
  filter: brightness(45%) grayscale(100%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.front {
  z-index: 10;
}

.home-oc-slider {
  width: 100%;
  height: 375px;
  margin-left: auto;
  margin-right: auto;
}

.home-oc-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  height: calc((100% - 30px) / 2) !important;
  padding: 0.7px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.slider-next-button-oc {
  position: absolute;
  top: 50%;
  right: -60px !important;
  background-image: url(../src/assets/fixed-images/next-button.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: contain;
  cursor: pointer;
  transform: translateY(-50%);
}

.slider-prev-button-oc {
  position: absolute;
  top: 50%;
  left: -60px !important;
  background-image: url(../src/assets/fixed-images/next-button.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: contain;
  transform: scaleX(-1) translateY(-50%);
  left: 0;
  cursor: pointer;
}

.oc-container {
  background-color: #f2f2f2;
}

.oc-border {
  border-radius: 50%;
  border: 1px solid #707070;
  background-color: #ffffff;
}

.oc-border img {
  border-radius: 50%;
  /* filter: grayscale(100%); */
  transition: all 0.4s;
}

.oc-border:hover img {
  filter: grayscale(0);
}

.wwd-banner {
  margin-top: -121px;
  padding-top: 250px;
  height: 650px;
  max-height: 650px;

}

.wwd-banner-pb {
  margin-top: -121px;
  padding-top: 250px;
  padding-bottom: 280px;
  height: 650px;
  max-height: 650px;

}

.wwd-banner-bubble {
  background-color: #ef1d1d;
  border-radius: 50%;
  height: 100%;
}

.wwd-banner-bubble-m {
  transform: translateY(50px);
}

.wwd-banner-title-m {
  margin-bottom: -100px;
}

.wwd-banner-bubble-text {
  font-family: 'RobotoLight', sans-serif;
  color: #ffffff;
  font-size: 30px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.wwa-banner {
  margin-top: -121px;
  padding-top: 250px;
  padding-bottom: 220px;
  height: 650px;
  max-height: 650px;

}

.wwa-banner-text {
  font-family: 'RobotoLight', sans-serif;
  font-size: 25px;
  line-height: 37px;
}

/* .react-date-picker-popper{
  z-index: 2000 !important;
} */

.wwa-banner-bg {
  position: absolute;
  top: 0px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.wwa-banner-bg-ratio {
  height: 100%;
  filter: grayscale(1);
  opacity: 18%;
}

.text-red {
  color: red;
}

.wwa-sec-title {
  font-family: 'RobotoBold', sans-serif;
  font-size: 32px;
  line-height: 48px;
}

.wwa-sec-text * {
  font-family: 'RobotoRegular', sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.wwa-sec2-button-hover:hover .wwa-sec2-button {
  flex-grow: 1;

}

.wwa-sec2-button-hover:hover .wwa-sec2-button p {
  text-align: end;
}

.wwa-sec2-button {
  border: none;
  background-color: #ef1d1d;
  border-radius: 0 50px 50px 0;
  color: #ffffff;
  transition: all 0.6s;
  /* width: 70%; */
}

.wwa-sec2-arrow {
  width: 60px !important;
}

.wwa-sec2-bg {
  height: 100%;
  width: 100%;
  filter: brightness(45%) grayscale(100%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.wwa-sec2-border {
  border-radius: 50%;
  transform: translateZ(0);
}

.wwa-sec2-border img {
  transform: scale(1);
  transition: all 0.5s;
  filter: brightness(69%);
}

.wwa-sec2-border:hover img {
  transform: scale(1.1);
}

.wwa-sec2-img-mob {
  width: 50%;
}

.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #ef1d1d);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  to {
    transform: rotate(1turn)
  }
}

.wwds-sec1-title {
  font-family: 'RobotoBold', sans-serif;
  font-size: 32px;
  line-height: 48px;
}

.ratio-custom {
  --bs-aspect-ratio: 85% !important;
}

.wwds-sec1-text * {
  font-family: 'RobotoRegular', sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.wwds-red-img {
  filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(1000%) contrast(0.8);
  /* filter: invert(21%) sepia(97%) saturate(2592%) hue-rotate(351deg) brightness(86%) contrast(93%); */
}

.work-card-arrow {
  width: 50px;
  height: 50px;
  transform: translateX(50%);
  opacity: 1;
  transition: all 0.4s;
}

.work-card-arrow-white {
  width: 50px;
  height: 50px;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.4s;
}

.work-card-content h3 {
  font-size: 40px;
  line-height: 40px;
}

.work-card-content p {
  font-family: 'RobotoRegular', sans-serif;
  font-size: 18px;
  line-height: 25px;
  width: 70%;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical; */
}

.work-card-gradient {
  background-image: linear-gradient(#000000, transparent);
}

.flip {
  transform: scaleX(-1);
}

.work-card-button:hover .work-card-arrow {
  opacity: 0;
}

.work-card-button:hover .work-card-arrow-white {
  opacity: 1;
}

.text-footer-contact {
  width: 50%;
}

.contact-info * {
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  transition: all 0.2s;
  margin-bottom: 0;
}

.contact-info a {
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  transition: all 0.2s;
  text-decoration: none;
}



.contact-info a:hover,
.contact-info p:hover {
  color: #ef1d1d;
}

.contact-map-mob {
  min-height: 380px;
}

.contact-map-mob iframe{
  height: 100%;
  width: 100%;
}


.bg-gray {
  background-color: #e5e5e5;
}

.contact-form-title {
  font-size: 30px;
  line-height: 45px;
  color: #ef1d1d;
}

.contact-form input,
.contact-form textarea {
  border: 1px solid #d0d0d0;
  border-radius: 30px;
  font-family: 'RobotoThin', sans-serif;
  font-size: 16px;
  padding: 10px 25px;
  width: 100%;
  /* resize: none; */
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
}

.jtt-page-number {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #e6e6e6;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: all 0.2s;
}

.jtt-page-number-red {
  background-color: #ef1d1d;
}

.jtt-navigation {
  width: 50px;
  height: 50px;
}

.jtt-navigation-abs {
  width: 50px !important;
  height: 50px;
  position: absolute !important;
  top: 50%;
  left: -62px;
  transform: translateY(-50%);
}

.jtt-navigation-abs-right {
  width: 50px !important;
  height: 50px;
  position: absolute !important;
  top: 50%;
  right: -62px;
  transform: translateY(-50%);
}

.jtt-navigation-abs-3 {
  width: 50px !important;
  height: 50px;
  position: absolute !important;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
}

.jtt-navigation-abs-right-3 {
  width: 50px !important;
  height: 50px;
  position: absolute !important;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
}

.jtt-form input,
.jtt-form textarea {
  border: 1px solid #d0d0d0;
  border-radius: 30px;
  font-family: 'RobotoThin', sans-serif;
  font-size: 14px;
  padding: 10px 40px 10px 25px;
  width: 100%;
  background-color: transparent;
}

.jtt-form input::placeholder {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.jtt-form input:focus,
.jtt-form textarea:focus,
.jtt-form select:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
.jtt-form input::-webkit-outer-spin-button,
.jtt-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.jtt-form input[type='number'] {
  -moz-appearance: textfield;
}

.jtt-form-title {
  border-bottom: 3px solid #ef1d1d;
}

.jtt-form-title h3 {
  font-size: 25px;
  line-height: 35px;
  padding: 0;
  color: #000000;
}

.form-select__single-value {
  color: #929292fe !important;
  font-family: 'Roboto-Light', sans-serif;
  font-size: 14px;
}

.form-select__indicator-separator {
  display: none;
}

.form-select__placeholder {
  font-size: 14px;
}

.form-select__control {
  border: 1px solid #d0d0d0 !important;
  border-radius: 30px !important;
  padding: 4px 5px 4px 20px;
  box-shadow: none !important;
}

.form-select__control:hover {
  outline: none !important;
  border-color: #d0d0d0 !important;
}

/* .form-select__menu div {
  background-color: initial;
  transition: all .4s;
}

.form-select__menu div:hover {
  background-color: red;
} */

.calendar-button {
  border-radius: 10px;
  background-color: #ef1d1d;
  border: none;
  color: white;
  padding: 2px 10px;
}

.form-calendar {
  border-radius: 30px !important;
  width: 100%;
  border: none !important;
}

.react-datepicker-popper {
  width: 100%;
  padding-right: 16px;
  min-width: 265px;
}

.form-calendar .react-datepicker__triangle {
  display: none;
}

.form-calendar .react-datepicker__month-container {
  background-color: #e6e6e6;
  border-radius: 30px !important;
  width: 100%;
}

.form-calendar .react-datepicker__day--outside-month {
  opacity: 0;
  pointer-events: none;
}

.form-calendar .react-datepicker__header {
  background-color: #e6e6e6;
  border-bottom: none;
  border-radius: 30px !important;
  padding-bottom: 0 !important;
}

.form-calendar .react-datepicker__header>div {
  border-bottom: 1px solid rgb(148, 148, 148);
  margin: 0 !important;
  padding: 10px 15px;
}

.form-calendar .react-datepicker__day-names {
  border-bottom: none !important;
  font-family: 'RobotoBold', sans-serif;
}

.form-calendar .react-datepicker__day--selected {
  background-color: #ef1d1d;
  color: white;
  border-radius: 50%;
}

.form-calendar .react-datepicker__day--selected:hover {
  background-color: #ef1d1d;
  color: white;
  border-radius: 50%;
}

.form-calendar .react-datepicker__day:hover {
  cursor: pointer;
  border-radius: 50%;
}

.form-calendar .react-datepicker__day {
  font-family: 'RobotoRegular', sans-serif;
}

.form-calendar .react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
}

.form-calendar .react-datepicker__month {
  margin: 0;
  padding: 0 15px 10px 15px;
}

.form-calendar .react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.form-calendar .react-datepicker__day--keyboard-selected {
  background-color: #ef1d1d;
  color: white;
  border-radius: 50%;
}

.form-calendar .react-datepicker__day--keyboard-selected:hover {
  background-color: #ef1d1d;
  color: white;
  border-radius: 50%;
}

.calendar-select {
  border: none;
  background-color: #e6e6e6;
  /* appearance: none; */
  font-size: 16px;
  font-family: 'RobotoBold', sans-serif;
  padding: 0 5px;
}

.calendar-select option {
  font-family: 'RobotoRegular', sans-serif;
}

.calendar-icon {
  position: absolute;
  top: 8px;
  right: 20px;
  z-index: -1;
}

.PhoneInputCountry {
  border: 1px solid #d0d0d0;
  margin-right: 0 !important;
  border-radius: 50px 0 0 50px;
  padding-left: 1em;
  padding-right: 1em;
}

.PhoneInputInput {
  border: 1px solid #d0d0d0;
  border-left: none !important;
  border-radius: 0 50px 50px 0 !important;
}

.PhoneInputCountrySelectArrow {
  content: url(../src/assets/fixed-images/select-arrow.svg) !important;
  width: 20px !important;
  margin-left: 20px !important;
  transform: scale(2) !important;
  border-style: none !important;
}

.PhoneInputCountrySelectArrow:hover {
  opacity: 1 !important;
}

.PhoneInputCountrySelect option {
  font-family: 'RobotoRegular', sans-serif;
  background-color: #e6e6e6;
}

.PhoneInputCountrySelect option:hover {
  background-color: #e6e6e6;
}

input[type='file'] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #d0d0d0;
  border-radius: 30px;
  font-family: 'RobotoThin', sans-serif;
  font-size: 14px;
  /* padding: 10px 40px 10px 25px; */
  width: 100%;
  background-color: transparent;
}

.upload-button {
  color: #ef1d1d;
  height: 100%;
  padding: 10px 25px;
  border: 1px solid #d0d0d0;
  /* border: none; */
  border-radius: 30px;
  transition: all 0.4s;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
}

.upload-button p {
  font-family: 'RobotoThin', sans-serif;
  font-size: 14px;
}

.upload-button img {
  filter: none;
  transition: all 0.2s;
}

.upload-button:hover {
  background-color: #ef1d1d;
  color: white;
}

.upload-button:hover img {
  filter: brightness(0) invert(1);
}

.form-important-text p {
  font-family: 'RobotoRegular', sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.text-custom-red {
  color: #ef1d1d;
}

.jtt-navigation-mob img {
  width: 35px;
  height: 35px;
}

.image-modal {
  background-color: #000000a0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
}

.image-modal-show {
  opacity: 1;
  pointer-events: auto;
}

.modal-arrow {
  width: 50px;
  height: 50px;
}

.w-80 {
  width: 80%;
}

.modal-close {
  width: 30px;
  height: 30px;
  top: -60px;
  right: 12px;
}

.modal-close img {
  width: 30px;
  height: 30px;
}

.grey-button {
  background-color: blue;
}

.error-text {
  font-family: 'RobotoRegular', sans-serif;
  font-size: 14px;
  color: #ef1d1d;
}

.error-border {
  border: 1px solid #ef1d1d !important;
}

.hide-img {
  opacity: 0;
  transition: all 1s;
}

.show-img {
  opacity: 1;
}

.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 3px !important;
}

.wwds-arrow-mob {
  z-index: 2;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 1199.9px) and (min-width: 991.9px) {
  .text-footer-sec1-1 {
    font-size: 39px;

  }



  .text-footer-sec1-2 {
    font-size: 39px;

  }
}

@media (max-width: 1399.9px) {
  .home-banner-button {
    width: calc((100vw / 2) - (1130px / 2));
    min-width: calc((100vw / 2) - (1130px / 2));
  }

  .home-banner-show-button:hover .home-banner-button {
    padding: 0 50px 0 calc((100vw / 2) - (1130px / 2));
  }

  .home-oc-slider {
    height: 320px;
  }

  .wwa-banner-bg-ratio {
    --bs-aspect-ratio: 70%;
  }

  #particles2 {
    right: calc((100vw / 2) - (880px / 2));
  }
}

@media (max-width: 1199.9px) {
  .black-back {
    width: calc((100vw / 2) - (950px / 2));
  }

  .title h1 {
    font-size: 107px !important;
  }

  .home-banner-button {
    width: calc((100vw / 2) - (956px / 2));
    min-width: calc((100vw / 2) - (956px / 2));
  }

  .home-banner-show-button:hover .home-banner-button {
    padding: 0 50px 0 calc((100vw / 2) - (956px / 2));
  }

  .related-work-ratio {
    --bs-aspect-ratio: 75%;
  }

  .home-oc-slider {
    height: 295px;
  }

  .wwa-banner-bg-ratio {
    --bs-aspect-ratio: 90%;
  }

  #particles2 {
    right: calc((100vw / 2) - (820px / 2));
  }
}

@media (max-width: 991.9px) {
  h1 {
    font-size: 80px !important;
    line-height: 100px !important;
  }

  .overlay-home-section {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .bubbleman-margin {
    margin-right: 100px;
  }

  .wwa-sec-title {
    text-align: center;
  }

  .wwa-sec-text * {
    text-align: center;
  }

  .title h1 {
    font-size: 67px !important;
  }

  .small-title h1 {
    font-size: 70px !important;
    line-height: 85px !important;
  }

  .black-back {
    width: 15%;
  }

  .menu-items ul li a:hover {
    color: white;
  }

  .footer-sec1-p2 {
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .footer-menu-items a:hover {
    color: #05090c;
  }

  .social-icons:hover {
    filter: invert(0%);
  }

  .home-banner-button {
    width: 100%;
    padding: 0 20px;
  }

  .home-banner-button p {
    opacity: 1;
    font-size: 16px;
  }

  .home-banner-slider-text h3 {
    font-size: 35px;
    line-height: 60px;
  }

  .home-banner-slider-text h2 {
    font-size: 47px;
    line-height: 47px;
  }

  .home-banner-slider-text .regular-home {
    font-size: 47px;
    line-height: 47px;
  }

  .social-icons-menu:hover {
    transform: translateY(0);
  }

  .red-button:hover {
    background-color: #ef1d1d;
    color: white;
  }

  .red-button-border:hover {
    background-color: #ef1d1d;
    color: white;
  }

  .black-button:hover {
    background-color: #030101;
    color: white;
  }

  .home-banner-button:hover {
    width: 100%;
    padding: 0 20px;
  }

  .home-banner-show-button:hover .home-banner-button {
    width: 100%;
    padding: 0 20px;
  }

  .wwdcard:hover .wwdcard-add-text {
    opacity: 0;
  }

  .related-work-ratio {
    --bs-aspect-ratio: 70%;
  }

  .home-oc-slider {
    height: 275px;
  }

  .home-banner-contact-icon:hover {
    transform: translateY(0);
  }

  .related-work-border:hover img {
    transform: scale(1);
  }

  .oc-border img {
    filter: grayscale(0);
  }

  .oc-border:hover img {
    filter: grayscale(0);
  }

  .wwd-banner-title-m {
    margin-bottom: -85px;
  }

  .wwa-banner-bg-ratio {
    --bs-aspect-ratio: 110%;
  }

  .wwa-sec2-button-hover:hover .wwa-sec2-button {
    flex-grow: 0;
  }

  .wwa-sec2-button-hover:hover .wwa-sec2-button p {
    text-align: start;
  }

  .wwa-sec2-border:hover img {
    transform: scale(1);
  }

  .wwa-banner {
    padding-bottom: 250px;
  }

  .work-card-arrow {
    transform: translateX(0);
  }

  .text-footer-contact {
    width: 100%;
  }

  .contact-info a:hover,
  .contact-info p:hover {
    color: #000000;
  }

  .wwa-sec2-bg {
    background-attachment: initial;
  }

  .modal-close {
    top: -45px;
  }

  .jtt-form input,
  .jtt-form textarea {
    font-size: 16px;
  }

  .form-select__single-value {
    font-size: 16px;
  }

  .form-select__placeholder {
    font-size: 16px;
  }

  .wwa-sec2-button {
    width: auto;
  }
}

@media (max-width: 767.9px) {
  .small-title h1 {
    font-size: 55px !important;
    line-height: 70px !important;
  }

  .wwdcard-img {
    max-width: 50px;
    max-height: 50px;
  }

  .home-oc-slider {
    height: 245px;
  }

  .related-work-ratio {
    --bs-aspect-ratio: 80%;
  }

  .wwa-banner-bg-ratio {
    --bs-aspect-ratio: 150%;
  }
}

@media (max-width: 575.9px) {
  h1 {
    font-size: 60px !important;
    line-height: 80px !important;
  }

  .title h1 {
    font-size: 54px !important;
  }

  .wwa-banner-text {
    font-size: 16px !important;
  }

  .add-margin-top {
    margin-top: -70px !important;
  }

  .small-title h1 {
    font-size: 41px !important;
    line-height: 61px !important;
  }

  .menu-items ul li a {
    font-size: 30px;
    list-style: 40px;
  }

  .logo {
    width: 75%;
  }

  .menu-button {
    width: 26px;
  }

  .home-sec1 {
    margin-top: -35px !important;
  }

  .wwdcard-img {
    max-width: 60px;
    max-height: 60px;
  }

  .wwdcard-text {
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding-top: 12px;
  }

  .home-banner-slider-text h3 {
    font-size: 30px;
    line-height: 40px;
  }

  .home-banner-slider-text h2 {
    font-size: 40px;
    line-height: 40px;
  }

  .home-banner-slider-text .regular-home {
    font-size: 40px;
    line-height: 40px;
  }

  .text-footer-sec1-1 {
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
  }

  .text-footer-sec1-2 {
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
  }

  .black-button {
    min-width: 150px;
  }

  .red-button {
    min-width: 150px;
  }

  .red-button-border {
    min-width: 150px;
  }

  .footer-follow-text {
    font-size: 25px;
    line-height: 33px;
  }

  .footer-menu-items a {
    font-size: 25px;
    line-height: 34px;
  }

  .footer-i-img img {
    height: 165px;
  }

  .home-banner-contact-icon {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
  }

  .home-banner-button-img {
    width: 55px !important;
    height: 55px;
  }

  .home-banner-contact-icon img {
    max-width: 25px;
    max-height: 25px;
  }

  .related-work-ratio {
    --bs-aspect-ratio: 120%;
  }

  .home-oc-slider {
    height: 17rem;
  }

  .slider-next-button,
  .slider-next-button-oc,
  .slider-next-button-oc-mob,
  .slider-next-button-rw,
  .slider-prev-button,
  .slider-prev-button-oc,
  .slider-prev-button-oc-mob,
  .slider-prev-button-rw {
    width: 35px;
    height: 35px;
  }

  .menu-items ul li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 28px;
    line-height: 50px;
  }

  .related-work-ratio {
    background-attachment: initial !important;
  }

  .close-menu-button {
    width: 25px;
    height: 25px;
  }

  .wwd-banner-bubble-text {
    font-family: 'RobotoLight', sans-serif;
    color: #ffffff;
    font-size: 25px;
  }

  .wwd-banner-title-m {
    margin-bottom: -90px;
  }

  .wwa-banner-bg-ratio {
    --bs-aspect-ratio: 240%;
  }

  .wwd-item-w {
    width: 50% !important;
  }

  .wwd-banner-bubble-w {
    width: 65% !important;
  }

  .work-card-content h3 {
    font-size: 30px;
    line-height: 35px;
  }

  .work-card-content p {
    font-size: 16px;
    line-height: 22px;
  }

  .wwds-sec1-title {
    font-family: 'RobotoBold', sans-serif;
    font-size: 25px;
    line-height: 40px;
  }

  .wwds-sec1-text * {
    font-family: 'RobotoRegular', sans-serif;
    font-size: 16px;
    line-height: 22px;
  }

  .upload-button {
    padding: 10px 10px;
  }

  .jtt-page-number {
    width: 60px;
    height: 60px;
  }

  .wwds-red-img {
    width: 60% !important;
  }

  .modal-close {
    width: 22px;
    height: 22px;
  }

  .modal-close img {
    width: 22px;
    height: 22px;
  }

  .react-datepicker-popper {
    padding-right: 12px;
    padding-left: 4px;
  }
}

@media (max-width: 410px) {
  h1 {
    font-size: 43px !important;
    /* line-height: 43px !important; */
  }

  .small-title h1 {
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .home-sec1 {
    margin-top: -22px !important;
  }

  .wwd-banner-bubble-text {
    font-family: 'RobotoLight', sans-serif;
    color: #ffffff;
    font-size: 20px;
  }

  .wwd-banner-title-m {
    margin-bottom: -75px;
  }

  .wwa-banner-bg-ratio {
    --bs-aspect-ratio: 300%;
  }

  /* .wwa-sec2-button {
    font-size: 14px;
  } */
  .wwa-sec2-img-mob {
    width: 100%;
  }

  .wwd-item-w {
    width: 70% !important;
  }

  .wwd-banner-bubble-w {
    width: 80% !important;
  }

  .wwdcard-img {
    max-width: 45px;
    max-height: 45px;
  }

  .wwdcard-text {
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    padding-top: 12px;
  }

  .wwds-red-img {
    width: 90% !important;
  }
}